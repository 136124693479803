document.addEventListener('DOMContentLoaded', function () {

  let navTouch = document.getElementById('navbar-touch');

  navTouch.addEventListener('click', function (event) {
    let navlist = document.getElementById('navlist');
    let burger = document.getElementById('burger');

    if (burger.classList.contains('is-opened')) {
      burger.classList.remove('is-opened');
    }else{
      burger.classList.add('is-opened');
    }

    if (navlist.classList.contains('is-opened')) {
      navlist.classList.remove('is-opened');
    }else{
      navlist.classList.add('is-opened');
    }
});

})