$(function() {
  /******************************************
  * SMOOTH SCROLLING to page anchor on click
  ******************************************/

  $("a[href*='#']:not([href='#'])").click(function() {
      if (
          location.hostname == this.hostname
          && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
      ) {
          var anchor = $(this.hash);
          var navHeight = document.getElementById('scrollspy-nav-container').clientHeight;
          anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
          if ( anchor.length ) {
              $("html, body").animate( { scrollTop: anchor.offset().top - 100 }, 200);
          }
      }
  });

  let collapse = document.getElementsByClassName('collapse');
  if (typeof(collapse) != 'undefined' && collapse != null) {
    $('.collapse').collapse({
      toggle: false,
    });
  }


  let onePageToggle = document.getElementById('nav-one-page-toggle');
  if (typeof(onePageToggle) != 'undefined' && onePageToggle != null) {

    /********************************
    * Sticking ONE PAGE NAV on top
    ********************************/

    var navPosition = $('#nav-one-page-toggle').offset();
    var navPositionTop = navPosition.top - 80;

    $(window).scroll(function () {
      var scrollPosition = $(window).scrollTop();
      if (scrollPosition >= navPositionTop) {
        $('.nav-one-page').addClass('scroll');
      }else{
        $('.nav-one-page').removeClass('scroll');
      }
    });


    /**************************
     * Open/Close ONE PAGE NAV
     **************************/

    $("#nav-one-page-toggle").click(function(){
      $("#scrollspy-nav-container").toggle(100);
      $("#nav-one-page-toggle").toggleClass('close');
    });

    $(".nav-link").click(function(){
      $("#scrollspy-nav-container").toggle(100);
      $("#nav-one-page-toggle").toggleClass('close');
    });
  }

  /***********************************
     * Hide/Show HELP CENTER SUBMENUS
  ***********************************/

  let menuParents = document.querySelectorAll('#menu-help-center .menu-item-has-children');
  if (typeof(menuParents) != 'undefined' && menuParents != null) {

     console.log(menuParents);
    [].forEach.call(menuParents, function (item) {

      let trigger = item.firstChild;
      let subMenuContainer = item.lastElementChild;

      if (item.classList.contains('current-menu-ancestor') || item.classList.contains('current-menu-ancestor')) {
        subMenuContainer.classList.add('show');
      }

      trigger.addEventListener('click', function (event) {
        event.preventDefault();

        if (subMenuContainer.classList.contains('hide')) {
          subMenuContainer.classList.remove('hide');
          subMenuContainer.classList.add('show');
        }else if(subMenuContainer.classList.contains('show')){
          subMenuContainer.classList.remove('show');
          subMenuContainer.classList.add('hide');
        }else{
          subMenuContainer.classList.add('show');
        }
      });
    })
  }

  /*******************************************
     * Show LM contents depending on cookie
  *******************************************/

  if (document.cookie.split(';').some(function(item) {
    return item.indexOf('lm_custom=yes') >= 0
  })) {
      console.log('OK LM');
      let lmOnlyElements = document.getElementsByClassName('lm-only');
      [].forEach.call(lmOnlyElements, function (element) {
        element.style.display = 'block';
    });
  }

});

